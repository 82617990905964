<template>
  <a-modal :title="title" :visible="open" :confirmLoading="uploading" @cancel="summaryHandleCancel" @ok="summaryHandle">
    <a-spin tip="汇总统计中..." :spinning="uploading">
      <a-checkbox @change="handleCheckedUpdateSupport" :checked="updateSupport != 0">
        是否更新已经存在的汇总数据
      </a-checkbox>
      <a-form-model-item label="汇总统计工资月份" prop="salaryDate" ref="summaryFrom">
        <a-month-picker
          style="width: 30%;text-align: center;"
          placeholder="请选择月份"
          v-model="salaryDate"
          value-format="YYYY-MM"
          format="YYYY-MM"
          allow-clear
          :getCalendarContainer="(trigger) => trigger.parentNode"
        />
      </a-form-model-item>
    </a-spin>
  </a-modal>
</template>

<script>
import { totalMonth } from '@/api/iot/summary'

export default {
  name: 'SummaryMonth',
  props: {},
  components: {},
  data() {
    return {
      title: '人员薪资汇总统计',
      open: false,
      // 是否禁用汇总
      uploading: false,
      updateSupport: 0,
      salaryDate: null
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  methods: {
    /** 窗体关闭 */
    summaryHandleCancel(e) {
      this.open = false
      this.fileList = []
      // 关闭后刷新列表
      this.$emit('ok')
    },
    /** 汇总窗体开启 */
    summaryHandleOpen(e) {
      this.open = true
    },
    /** 处理汇总统计 */
    summaryHandle() {
      this.uploading = true
      const formData = new FormData()
      console.log('salaryDate:', this.salaryDate)
      if (!this.salaryDate) {
        this.$warning({
          title: 'Warning',
          content: '请先选择要统计的工资月份'
        })
        this.uploading = false
        return
      }
      formData.append('updateSupport', this.updateSupport)
      formData.append('salaryDate', this.salaryDate)
      totalMonth(formData)
        .then(response => {
          this.fileList = []
          // this.$message.success(response.msg)
          this.$message.info(response.msg)
          this.open = false
          this.$emit('ok')
          this.uploading = false
        })
        .catch(() => {
          this.uploading = false
        })
    },
    handleCheckedUpdateSupport() {
      this.updateSupport = this.updateSupport === 0 ? 1 : 0
    }
  }
}
</script>
