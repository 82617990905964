var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.open,
        confirmLoading: _vm.uploading,
      },
      on: { cancel: _vm.summaryHandleCancel, ok: _vm.summaryHandle },
    },
    [
      _c(
        "a-spin",
        { attrs: { tip: "汇总统计中...", spinning: _vm.uploading } },
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.updateSupport != 0 },
              on: { change: _vm.handleCheckedUpdateSupport },
            },
            [_vm._v(" 是否更新已经存在的汇总数据 ")]
          ),
          _c(
            "a-form-model-item",
            {
              ref: "summaryFrom",
              attrs: { label: "汇总统计工资月份", prop: "salaryDate" },
            },
            [
              _c("a-month-picker", {
                staticStyle: { width: "30%", "text-align": "center" },
                attrs: {
                  placeholder: "请选择月份",
                  "value-format": "YYYY-MM",
                  format: "YYYY-MM",
                  "allow-clear": "",
                  getCalendarContainer: function (trigger) {
                    return trigger.parentNode
                  },
                },
                model: {
                  value: _vm.salaryDate,
                  callback: function ($$v) {
                    _vm.salaryDate = $$v
                  },
                  expression: "salaryDate",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }